import React, { useState } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Layout from '../components/LayoutLanding'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import '../styles/styles.scss'
import styled from 'styled-components'
import Launch from '../components/Launch'

const richardWealeReadersClub = ({ className }) => {
  return (
    <Layout>
      <div className="wrap">
        <StaticQuery
          query={graphql`
            query {
              image: file(relativePath: { eq: "apprentice.jpg" }) {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              book: file(relativePath: { eq: "apprentice3dbook.png" }) {
                childImageSharp {
                  fixed(width: 420) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          `}
          render={data => {
            const image = data.image.childImageSharp.fluid
            const book = data.book.childImageSharp.fixed
            // const [clickState, setClickState] = useState(false)

            return (
              <BackgroundImage className={className} fluid={image}>
                <div className="launch-container">
                  <div className="launch-title">
                    <h1>
                      GET FREE ADVANCE COPIES OF EVERYTHING
                      <br /> WHEN YOU JOIN THE RICHARD WEALE LAUNCH TEAM
                    </h1>
                  </div>

                  <div className="landing">
                    <div className="launch-book">
                      <Img fixed={book} alt="" />
                      <Launch />

                      {/* <Pop /> */}
                      {/* <button
                        className="launch-button"
                        onClick={() => {
                          setClickState(!clickState)
                        }}
                      >
                        Join Launch Team
                      </button>
                      {clickState ? (
                        <Pop
                          onChange={value => setNameState(value)}
                          onClick={() => setClickState(!clickState)}
                        />
                      ) : null} */}
                    </div>
                    <div className="launch-wrap">
                      <p>
                        I have always said that authors wouldn’t be anywhere
                        without our readers. The one group of people that really
                        matters to authors, is YOU, the reader.
                      </p>
                      <p>
                        I have found a way to include you as part of my journey.
                        Your encouragement and kind reviews keep me going. Here
                        is a way we can work together, and I get to thank you
                        for your kindness.
                      </p>
                      <p>Today, I’m launching my Richard Weale Launch Team.</p>
                      <p>
                        Sign up below to join the team, and, in return I’ll send
                        you:
                      </p>
                      <p>
                        FREE advance preview copies of EVERYTHING new I release,
                        including ebooks and audiobooks.
                      </p>
                      <p>
                        You will get them FIRST, before anyone else, before they
                        are published.
                      </p>
                      <p>
                        This is completely different to belonging to my Reader's
                        Club. You will be part of a select group of super
                        amazing readers, helping me to launch and expand my
                        work.
                      </p>
                      <p>
                        If this sounds like an enterprise you’d like to be a
                        part of, there is only one thing I ask. As a part of the
                        Launch Team I will be asking you to leave a review of
                        the books you receive within a certain time period after
                        receiving your book. This would normally be on launch
                        day, about two weeks later. I hope that’s not too much
                        to ask!
                      </p>
                      <p>
                        {' '}
                        If you would like to join the launch team, just sign up
                        here.
                      </p>
                      <p>
                        When you sign up, you will be asked to confirm your
                        email address, and I’ll send you more details. If you
                        have any questions, please email me at
                        richard@richardweale.com
                      </p>
                      <p>Thank you and best wishes,</p>
                      <p>Richard</p>
                    </div>
                  </div>
                </div>
              </BackgroundImage>
            )
          }}
        />
      </div>
    </Layout>
  )
}

const StyledBackgroundSectionFoot = styled(richardWealeReadersClub)`
  width: 100%;
  display: flex;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  
  }
`

export default StyledBackgroundSectionFoot
