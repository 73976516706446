import React, { useState } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Paper from '../images/icons/paper-plane (2).svg'

const Launch = () => {
  const [email, setEmail] = useState('')
  const [valid, setValid] = useState(true)
  const endpoint =
    'https://richardweale.us12.list-manage.com/subscribe/post?u=7679e98524c57f81cfe410d52&amp;id=593f8435ea'

  const handleSubmit = e => {
    e.preventDefault()
    addToMailchimp(email, endpoint)
      .then(data => {
        if (data.result === 'success') {
          setValid(true)
          return window.open('https://www.richardweale.com/success/')
        } else {
          setValid(false)
        }
      })
      .catch(error => {
        // Errors in here are client side
        // Mailchimp always returns a 200
      })
  }

  const handleEmailChange = event => {
    setEmail(event.currentTarget.value)
  }

  return (
    <div className="launch-form">
      {/* <div className="launch-sell"></div> */}
      <form onSubmit={handleSubmit}>
        <div>
          <input
            type="text"
            name="form-name"
            placeholder="Email"
            onChange={handleEmailChange}
          />
        </div>
        {valid ? null : (
          <div className="valid-email">
            <p>This email is invalid or already taken!</p>
            <p>Please try another...</p>
          </div>
        )}
        <button type="submit">
          JOIN MY LAUNCH TEAM
          <img id="paper" src={Paper} alt="paper airoplane icon for email" />
        </button>
      </form>
    </div>
  )
}

export default Launch
